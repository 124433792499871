.transactionDetails {
    text-align: center;
}

.title {
    font-weight: bold;
    margin-top: 10px;
}

.details {
    margin-left: 20px;
}

.itemsList {
    list-style-type: none;
    padding: 0;
}

.item {
    padding: 10px;
    margin-top: 10px;
    border-radius: 5px;
}